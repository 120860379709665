import React from 'react';

import { MODALS } from '../utils/constants';

function BroadcastImages({ images, setSelectedImage, setModalOpen }) {

    function handleImageDeleteClick(image) {
        setSelectedImage(image);
        setModalOpen(MODALS.deleteBroadcastImage);
    }

    return (
        <div className="card">
            <div className="card__account-header">
                <h2 className="card__title">Images</h2>
                <div>
                    <button
                        className="mdl-button mdl-button--raised button-blue"
                        onClick={() => setModalOpen(MODALS.broadcastImage)}
                        style={{ minWidth: '150px', float: 'right', marginBottom: '12px' }}>
                        Add image
                    </button>
                </div>
            </div>
            {!!(images && images.length) && (
                <table style={{ width: "100%", tableLayout: "fixed" }} className="mdl-data-table mdl-js-data-table mdl-data-table--selectable mdl-shadow--2dp">
                    <thead>
                        <tr>
                            <th className="mdl-data-table__cell--non-numeric">Image</th>
                            <th className="mdl-data-table__cell--non-numeric">Title</th>
                            <th className="mdl-data-table__cell--non-numeric">Author</th>
                            <th className="mdl-data-table__cell--non-numeric">Source</th>
                            <th className="mdl-data-table__cell--non-numeric">View image</th>
                            <th className="mdl-data-table__cell--non-numeric">Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {images.map(image => (
                            <tr key={image.filepath}>
                                <td>
                                    {image.url && (
                                        <img style={{ maxWidth: "100%", maxHeight: "100px" }} src={image.url} />
                                    )}
                                </td>
                                <td className="mdl-data-table__cell--non-numeric">
                                    {image.title}
                                </td>
                                <td className="mdl-data-table__cell--non-numeric">
                                    {image.author}
                                </td>
                                <td className="mdl-data-table__cell--non-numeric">
                                    {image.source}
                                </td>
                                <td className="mdl-data-table__cell--non-numeric">
                                    <a href={image.url} target="_blank" rel="noreferrer">
                                        <button className="mdl-button mdl-js-button mdl-button--icon mdl-button--accent">
                                            <span className="material-icons">
                                                open_in_new
                                            </span>
                                        </button>
                                    </a>
                                </td>
                                <td className="mdl-data-table__cell--non-numeric">
                                    <button className="mdl-button mdl-js-button mdl-button--icon mdl-button--accent"
                                        onClick={() => handleImageDeleteClick(image)}>
                                        <span className="material-icons">
                                            delete
                                        </span>
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
}

export default BroadcastImages;
