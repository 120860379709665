import React from 'react';

import { MODALS } from '../utils/constants';

function BroadcastAudio({ audios, setSelectedAudio, setModalOpen }) {

    function handleDeleteAudioClick(audio) {
        setSelectedAudio(audio);
        setModalOpen(MODALS.deleteBroadcastAudio);
    }

    function formatAudioFilepath(filepath) {
        if (!filepath) return '';

        return `...${filepath.slice(filepath.length - 12, filepath.length)}`;
    }

    return (
        <React.Fragment>
            <div style={{ marginTop: "24px" }} className="card__account-header">
                <h2 className="card__title">Audio</h2>
                <div className="card__button-container">
                    <button
                        className="mdl-button mdl-button--raised button-blue"
                        onClick={() => setModalOpen(MODALS.broadcastAudio)}
                        style={{ minWidth: '150px', float: 'right', marginBottom: '12px' }}>
                        Upload audio
                    </button>
                </div>
            </div>
            {!!(audios && audios.length) && (
                <table style={{ width: "100%", tableLayout: "fixed", marginBottom: "28px" }}
                    className="mdl-data-table mdl-js-data-table mdl-data-table--selectable mdl-shadow--2dp">
                    <thead>
                        <tr>
                            <th className="mdl-data-table__cell--non-numeric">File</th>
                            <th className="mdl-data-table__cell--non-numeric">Listen/download</th>
                            <th className="mdl-data-table__cell--non-numeric">Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {audios.map(audio => (
                            <tr key={audio.filepath}>
                                <td className="mdl-data-table__cell--non-numeric">
                                    {formatAudioFilepath(audio.filepath)}
                                </td>
                                <td className="mdl-data-table__cell--non-numeric">
                                    <a href={audio.url} target="_blank" rel="noreferrer">
                                        <button className="mdl-button mdl-js-button mdl-button--icon mdl-button--accent">
                                            <span className="material-icons">
                                                video_library
                                            </span>
                                        </button>
                                    </a>
                                </td>
                                <td className="mdl-data-table__cell--non-numeric">
                                    <button onClick={() => handleDeleteAudioClick(audio)}
                                        className="mdl-button mdl-js-button mdl-button--icon mdl-button--accent">
                                        <span className="material-icons">
                                            delete
                                        </span>
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </React.Fragment>
    );
}

export default BroadcastAudio;
