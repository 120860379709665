import React, { useState } from 'react';

import firebase from '../firebase';
import { MODALS } from '../utils/constants';

const FIELDS = {
    email: 'email',
    title: 'title'
};

function CreateBroadcastModal({ setModalOpen, getUserBroadcasts, setAccessLink }) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [broadcastEmail, setBroadcastEmail] = useState('');
    const [broadcastTitle, setBroadcastTitle] = useState('');

    function handleSubmit(e) {
        e.preventDefault();
        setIsSubmitting(true);

        firebase.createBroadcast(broadcastEmail, broadcastTitle)
            .then(accessLink => {
                getUserBroadcasts();

                if (!accessLink) return setModalOpen(null);

                setAccessLink(accessLink);
                setModalOpen(MODALS.broadcastAccessLink);
            })
            .catch(err => alert(`Something went wrong. ${err.message || ''}`))
            .finally(() => {
                setIsSubmitting(false);
            });
    }

    function onCancelClick() {
        setModalOpen(null);
    }

    function handleChange(e) {
        const { value, name } = e.target;
        switch(name) {
            case FIELDS.email:
                setBroadcastEmail(value);
                break;
            case FIELDS.title:
                setBroadcastTitle(value);
                break;
        }
    }

    return (
        <div>
            <div className="full-page-overlay" onClick={onCancelClick} />
            <div className="modal-container">
                <div className="modal-container__modal edit-modal">
                    <form onSubmit={handleSubmit}>
                        <div className="mdl-textfield" style={{ width: '100%' }}>
                            <label className="mdl-typography--body-1" htmlFor="title">
                                Broadcast title
                            </label>
                            <input
                                id="title"
                                className="mdl-textfield__input edit-modal__input"
                                type="text"
                                name={FIELDS.title}
                                value={broadcastTitle}
                                onChange={handleChange} />
                        </div>
                        <div className="mdl-textfield" style={{ width: '100%' }}>
                            <label className="mdl-typography--body-1" htmlFor="email">
                                Email address
                            </label>
                            <input
                                id="email"
                                className="mdl-textfield__input edit-modal__input"
                                type="email"
                                name={FIELDS.email}
                                value={broadcastEmail}
                                onChange={handleChange} />
                        </div>
                        <div className="edit-modal__buttons">
                            <button
                                className="mdl-button mdl-button--raised button-responsive"
                                name="cancel"
                                onClick={onCancelClick}
                                disabled={isSubmitting}>
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="mdl-button mdl-button--raised button-blue button-responsive"
                                name="save"
                                disabled={isSubmitting}>
                                Create
                                {isSubmitting &&
                                    <i className="material-icons rotating" role="presentation">
                                        rotate_right
                                    </i>
                                }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default CreateBroadcastModal;
