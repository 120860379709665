import React, { useState } from 'react';

import firebase from '../firebase';

const FIELDS = {
    author: 'author',
    title: 'title',
    source: 'source',
    file: 'file',
};

function BroadcastImageModal({ setModalOpen, selectedBroadcast, getBroadcastDetails }) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [imageTitle, setImageTitle] = useState('');
    const [imageAuthor, setImageAuthor] = useState('');
    const [imageSource, setImageSource] = useState('');
    const [imageFile, setImageFile] = useState('');
    const [imageFilename, setImageFilename] = useState('');

    function handleSubmit(e) {
        e.preventDefault();
        setIsSubmitting(true);

        const broadcastId = selectedBroadcast.id;
        firebase.uploadBroadcastImage({ broadcastId, imageTitle, imageAuthor, imageSource, imageFile })
            .then(() => getBroadcastDetails(broadcastId))
            .catch(err => alert(`Something went wrong. ${err.message || ''}`))
            .finally(() => {
                setIsSubmitting(false);
                setModalOpen(null);
            });
    }

    function onCancelClick() {
        setModalOpen(null);
    }

    function handleChange(e) {
        const { value, name, files } = e.target;
        switch(name) {
            case FIELDS.author:
                setImageAuthor(value);
                break;
            case FIELDS.title:
                setImageTitle(value);
                break;
            case FIELDS.source:
                setImageSource(value);
                break;
            case FIELDS.file:
                setImageFilename(value);
                setImageFile(files[0]);
                break;
        }
    }

    return (
        <div>
            <div className="full-page-overlay" onClick={onCancelClick} />
            <div className="modal-container">
                <div className="modal-container__modal edit-modal">
                    <form onSubmit={handleSubmit}>
                        <div className="mdl-textfield" style={{ width: '100%' }}>
                            <label className="mdl-typography--body-1" htmlFor="title">
                                Title
                            </label>
                            <input
                                required
                                id="title"
                                className="mdl-textfield__input edit-modal__input"
                                type="text"
                                name={FIELDS.title}
                                value={imageTitle}
                                onChange={handleChange} />
                        </div>
                        <div className="mdl-textfield" style={{ width: '100%' }}>
                            <label className="mdl-typography--body-1" htmlFor="author">
                                Author
                            </label>
                            <input
                                required
                                id="author"
                                className="mdl-textfield__input edit-modal__input"
                                type="text"
                                name={FIELDS.author}
                                value={imageAuthor}
                                onChange={handleChange} />
                        </div>
                        <div className="mdl-textfield" style={{ width: '100%' }}>
                            <label className="mdl-typography--body-1" htmlFor="source">
                                Source
                            </label>
                            <input
                                required
                                id="source"
                                className="mdl-textfield__input edit-modal__input"
                                type="text"
                                name={FIELDS.source}
                                value={imageSource}
                                onChange={handleChange} />
                        </div>
                        <div className="mdl-textfield" style={{ width: '100%' }}>
                            <label className="mdl-typography--body-1" htmlFor="image">
                                Image
                            </label>
                            <input
                                required
                                id="image"
                                className="mdl-textfield__input edit-modal__input"
                                type="file"
                                name={FIELDS.file}
                                value={imageFilename}
                                onChange={handleChange} />
                        </div>
                        <div className="edit-modal__buttons">
                            <button
                                className="mdl-button mdl-button--raised button-responsive"
                                name="cancel"
                                onClick={onCancelClick}
                                disabled={isSubmitting}>
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="mdl-button mdl-button--raised button-blue button-responsive"
                                name="save"
                                disabled={isSubmitting}>
                                Upload
                                {isSubmitting &&
                                    <i className="material-icons rotating" role="presentation">
                                        rotate_right
                                    </i>
                                }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default BroadcastImageModal;
