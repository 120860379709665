import React, { useState } from 'react';

import firebase from '../firebase';
import ClipboardButton from './ClipboardButton';

function BroadcastLinkModal({ setModalOpen, selectedBroadcast, accessLink, setAccessLink }) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [email, setEmail] = useState('');

    function handleSubmit(e) {
        e.preventDefault();
        setIsSubmitting(true);
        setAccessLink('');

        const broadcastId = selectedBroadcast.id;
        firebase.getBroadcastAccessLink(broadcastId, email)
            .then(link => {
                if (!link) return alert("Something went wrong.");

                setAccessLink(link);
            })
            .catch(err => alert(`Something went wrong. ${err.message || ''}`))
            .finally(() => {
                setIsSubmitting(false);
            });
    }

    function onCancelClick() {
        setModalOpen(null);
    }

    function handleChange(e) {
        const { value } = e.target;
        setEmail(value);
    }

    return (
        <div>
            <div className="full-page-overlay" onClick={onCancelClick} />
            <div className="modal-container">
                <div className="modal-container__modal edit-modal">
                    {accessLink && (
                        <div>
                            <div className="card__item-group-key text-bold">Access link:</div>
                            <div className="card__item-group-value card__item-group-value--access-link">
                                {accessLink}
                            </div>
                            <ClipboardButton value={accessLink} />
                            <div className="edit-modal__buttons">
                                <button
                                    className="mdl-button mdl-button--raised button-responsive"
                                    name="cancel"
                                    onClick={onCancelClick}
                                    disabled={isSubmitting}>
                                    Close
                                </button>
                            </div>
                        </div>
                    )}
                    {!accessLink && (
                        <form onSubmit={handleSubmit}>
                            <div>
                                Enter an email address to generate an access link.
                                <br />
                                Expiry time is 1 week.
                            </div>
                            <div className="mdl-textfield" style={{ width: '100%' }}>
                                <label className="mdl-typography--body-1" htmlFor="email">
                                    Email address
                                </label>
                                <input
                                    id="email"
                                    className="mdl-textfield__input edit-modal__input"
                                    required
                                    type="email"
                                    name="email"
                                    value={email}
                                    onChange={handleChange} />
                            </div>
                            <div className="edit-modal__buttons">
                                <button
                                    className="mdl-button mdl-button--raised button-responsive"
                                    name="cancel"
                                    onClick={onCancelClick}
                                    disabled={isSubmitting}>
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="mdl-button mdl-button--raised button-blue button-responsive"
                                    name="save"
                                    disabled={isSubmitting}>
                                    Create
                                    {isSubmitting &&
                                        <i className="material-icons rotating" role="presentation">
                                            rotate_right
                                        </i>
                                    }
                                </button>
                            </div>
                        </form>
                    )}
                </div>
            </div>
        </div>
    );
}

export default BroadcastLinkModal;
