import React, { useEffect } from 'react';

import { PATHNAMES, MODALS } from '../utils/constants';

import NavLink from './NavLink';

function BroadcastIndex({ broadcastList, setPathname, isAdmin, setModalOpen, getUserBroadcasts }) {
    useEffect(() => {
        getUserBroadcasts();
    }, []);

    if (!broadcastList || !broadcastList.length) return (
        <div>
            No broadcasts found
        </div>
    );

    return (
        <div>
            {isAdmin && (
                <button
                    className="mdl-button mdl-button--raised button-blue"
                    onClick={() => setModalOpen(MODALS.createBroadcast)}
                    style={{ minWidth: '150px', float: 'right', marginBottom: '12px' }}>
                    Create broadcast
                </button>
            )}
            <table style={{ width: "100%", tableLayout: "fixed" }} className="mdl-data-table mdl-js-data-table mdl-data-table--selectable mdl-shadow--2dp">
                <thead>
                    <tr>
                        <th className="mdl-data-table__cell--non-numeric">Broadcast title</th>
                        <th className="mdl-data-table__cell--non-numeric">Broadcast ID</th>
                    </tr>
                </thead>
                <tbody>
                    {broadcastList.map(broadcast => (
                        <tr key={`broadcast-${broadcast.id}`}>
                            <td className="mdl-data-table__cell--non-numeric">
                                <NavLink
                                    setPathname={setPathname}
                                    className="mdl-navigation__link"
                                    href={[PATHNAMES.broadcasts, broadcast.id].join("/")}>
                                    {broadcast.title}
                                </NavLink>
                            </td>
                            <td className="mdl-data-table__cell--non-numeric">
                                <NavLink
                                    setPathname={setPathname}
                                    className="mdl-navigation__link"
                                    href={[PATHNAMES.broadcasts, broadcast.id].join("/")}>
                                    {broadcast.id}
                                </NavLink>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default BroadcastIndex;
