import React from 'react';

import { ADMIN_PATHNAMES, PATHNAMES, BROADCAST_DETAIL_PATH_REGEX } from '../utils/constants';
import CustomerSearch from '../components/CustomerSearch';
import SearchResults from '../components/SearchResults';
import ShowHours from '../components/ShowHours';
import ListenerPicks from '../components/ListenerPicks';
import BroadcastDetails from '../components/BroadcastDetails';
import BroadcastIndex from '../components/BroadcastIndex';

function renderAdminPage(roles, pathname, props) {
    if (!roles.admin) return null;

    const { searchResult, setSearchResult, customerEmail, setCustomerEmail, setModalOpen } = props;

    if (BROADCAST_DETAIL_PATH_REGEX.test(pathname)) {
        const broadcastId = pathname.match(BROADCAST_DETAIL_PATH_REGEX)[1];
        return <BroadcastDetails { ...props } isAdmin={true} broadcastId={broadcastId} />;
    }

    if (!ADMIN_PATHNAMES.includes(pathname)) return null;

    if (pathname === PATHNAMES.listenerPicks) return <ListenerPicks />;

    if (pathname === PATHNAMES.broadcasts) return <BroadcastIndex { ...props } isAdmin={true} />;

    return (
        <>
            <CustomerSearch
                pathname={pathname}
                setSearchResult={setSearchResult}
                customerEmail={customerEmail}
                setCustomerEmail={setCustomerEmail} />
            <SearchResults
                pathname={pathname}
                searchResult={searchResult}
                setModalOpen={setModalOpen} />
        </>
    );
}

function renderHostPage(roles, pathname, props) {
    if (!roles.host) return null;

    if (!roles.admin && pathname === PATHNAMES.home) {
        return props.authUserShows.length
            ? <ShowHours { ...props } />
            : <BroadcastIndex { ...props } />;
    }

    if (!roles.admin && pathname === PATHNAMES.broadcasts) return <BroadcastIndex { ...props } />;

    if (!roles.admin && BROADCAST_DETAIL_PATH_REGEX.test(pathname)) {
        const broadcastId = pathname.match(BROADCAST_DETAIL_PATH_REGEX)[1];
        return <BroadcastDetails { ...props } broadcastId={broadcastId} />;
    }

    if (pathname === PATHNAMES.payout) return <ShowHours { ...props } />;

    return null;
}

export {
    renderAdminPage,
    renderHostPage,
};
