import React, { useState } from 'react';

import firebase from '../firebase';
import { MODALS } from '../utils/constants';

function RefreshAccessLinkButton({ email, broadcastId, setAccessLink, setModalOpen }) {
    const [isLoading, setIsLoading] = useState(false);

    function onClick() {
        setIsLoading(true);
        firebase.getBroadcastAccessLink(broadcastId, email)
            .then(accessLink => {
                if (!accessLink) alert('Something went wrong. Unable to generate access link.');

                setAccessLink(accessLink);
                setModalOpen(MODALS.broadcastAccessLink);
            })
            .catch(err => alert(`Something went wrong. ${err.message || ''}`))
            .finally(() => setIsLoading(false));
    }

    const loadingClass = isLoading ? 'clipboard-button--loading' : '';
    return (
        <button className={`clipboard-button ${loadingClass}`}
            onClick={onClick}>
            <span style={{ marginRight: "2px" }} className="material-icons">
                refresh
            </span>
            Refresh access link
        </button>
    );
}

export default RefreshAccessLinkButton;
